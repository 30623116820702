import { parsePhoneNumberFromString } from 'libphonenumber-js';

const isValidPhoneNumber = (phNumber, countryCode = 'US') => {
  if (phNumber) {
    const phoneNumber = parsePhoneNumberFromString(phNumber, countryCode);
    return phoneNumber && phoneNumber.isValid();
  }
};

export { isValidPhoneNumber };
